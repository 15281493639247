import { mapActions, mapMutations } from 'vuex'
import {
  getDefaultFormAppointment,
  getDefaultFormClient,
  getDefaultFormClientSummary,
  getDefaultFormClientTabs,
  getDefaultFormReferral,
  getDefaultFormReminderItem,
  getDefaultValidationsAppointment,
  getDefaultValidationsClient,
  getDefaultValidationsClientTabs,
  getDefaultValidationsCompany,
  getDefaultValidationsReferral,
  getDefaultValidationsReminderItem,
} from './methods/appointment_default_data.js'
import {
  APPOINTMENT_SOURCE_MEDODS,
  DOCTOR_SCHEDULE,
  getFormData,
  INSERT_APPOINTMENT_TYPES,
  MODAL_TYPES,
  PROMOBOT_PERMIT_MODAL_TYPES,
} from './consts.js'
import { WITHOUT_CABINET_ID } from './cabinets_list/const.js'
import { getDefaultCompanyData } from '@/vue_components/companies/components/consts.js'
import { clientEndpoint } from '@/api_entities/client/clients_endpoint.js'
import { appointmentsEndpoint } from '@/api_entities/appointments/appointments_endpoint'
import { getAvailableDuration } from './methods/helpers.js'
import { fetchCompanyTypes } from '@/vue_components/companies/rest.js'
import {
  createReferral,
  createReminder,
  deleteWaitingListItem,
  fetchAppointmentByClient,
  fetchAppointmentById,
  fetchAppointmentUsers,
  fetchCabinets,
  fetchClientById,
  fetchClientByPhone,
  fetchSpecialties,
  fetchWorkTimesByUser,
  getAppointmentLastSms,
  updateAppointmentStatus,
  updateReminder,
} from './rest'
import {
  MODE_CLIENT_INSERT,
  MODE_WAITING_LIST_INSERT,
  MODE_WAITING_LIST_MOVE,
} from '@/plugins/schedule/scheduleCommon/const'
import formatter from '@/lib/formatters/formatter'
import {
  appointmentAdapter,
  clientAdapter,
  formatUser,
  referralAdapter,
  reminderAdapter,
} from '@/vue_components/doctor_schedules/adapters'
import { APPOINTMENT_STATUS, MIN_DURATION } from '@/vue_components/appointment/const'
import { CONFIRMATION_MODES } from '@/vue_components/mixins/modals/const'
import { reminderEndpoint } from '@/vue_components/reminders/reminder_endpoint'
import { checkSnils } from '@/specific/clients/methods/snils/checkSnils'
import { cloneDeep } from 'lodash'
import { Intent } from '@/services/intents'
import { birthdate } from '@/lib/validators/rules'
import { fillClientFormByPromobotData } from '@/vue_components/doctor_schedules/promobotHelpers'

// Экранирование случая когда пользователь был удален
export const clientGetSummary = (params) => {
  return clientEndpoint.getSummary(params)
    .catch((err) => {
      if (err.status === 410) {
        Utils.reportError('doctor_schedules_methods:clientGetSummary()', t('client_was_deleted'))(err)
      }
    })
}

export const issueDateValidation = (newValue, modal, context) => {
  const rules = [birthdate].filter(Boolean)
  const messages = {
    birthdate: t('incorrect_date'),
  }
  context.validateRules('issueDate', newValue, rules, messages, modal.validationMessages.document)
}

export const validateSnils = (modal, setValidationMessages) => {
  if (modal.form.client.sendDataToEgisz && !checkSnils(modal.form.client.snils)) {
    const errorText = t('egisz.snils.incorrect')

    setValidationMessages(
      modal.validationMessages,
      { snils: [errorText] }
    )

    Utils.reportError('validateSnils', errorText)()

    return false
  }

  return true
}

export default {
  ...mapActions([
    'setActiveScheduleStore',
    'setScheduleDate',
    'setScheduleDaysCount',
    'setScheduleCurrentClinicId',
    'loadInitialDataSchedule',
  ]),
  ...mapMutations({
    setGridMode: 'SET_MODE_GRID_SCHEDULE',
  }),

  //region waiting list
  openCreateWaitingItemForm () {
    this.openModal(MODAL_TYPES.CREATE_WAITING_ITEM)
  },

  openEditWaitingItemForm (item) {
    this.openModal(MODAL_TYPES.EDIT_WAITING_ITEM)
    // ждём маунт компонента
    this.$nextTick(() => {
      this.$refs[MODAL_TYPES.EDIT_WAITING_ITEM].openEditWaitingItemForm(item)
    })
  },

  openCreateWaitingItemFormWithClient (clientInfo) {
    if (clientInfo.id) {
      this.modal[MODAL_TYPES.CREATE_WAITING_ITEM].clientId = clientInfo.id
      Utils.URL.silentSetQueryParam('insert_client', clientInfo.id)
    } else {
      this.modal[MODAL_TYPES.CREATE_WAITING_ITEM].clientPhone = clientInfo.phone
      Utils.URL.silentSetQueryParam('insert_phone', clientInfo.phone)
    }
    this.openModal(MODAL_TYPES.CREATE_WAITING_ITEM)
  },
  //endregion waiting list

  //region create appointment
  openCreateAppointmentForm (params) {
    const modalType = MODAL_TYPES.CREATE_APPOINTMENT
    const modal = this.modal[modalType]
    // открыть и заблокировать модалку
    this.openModal(modalType)
    this.startLoading(modalType)

    // показать в сетке заглушку на месте записи
    this.reservedAppointmentShow = true
    this.reservedAppointment = {
      id: Date.now(),
      date: params.date,
      time: params.time,
      user_id: params.user_id,
      duration: this.GET_SCHEDULE_DURATION,
      initiator: {
        id: this.GET_APP_CONF.current_user.id,
        surname: this.GET_APP_CONF.current_user.surname,
      },
      clinic_id: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
    }

    this.resetModalFormData()

    // заполнить форму существующими данными
    this.modal.formData.doctor = {
      id: params.user.id,
      shortName: params.user.short_name,
      avatarTag: params.user.avatar_tag,
      denySmsInformation: params.user.deny_sms_information,
    }

    this.modal.formData.maxDuration =
      getAvailableDuration(params._worktime, params.time)

    modal.form = {
      ...modal.form,
      appointmentSourceId: APPOINTMENT_SOURCE_MEDODS,
      clinicId: params.clinic_id,
      date: params.date,
      time: params.time,
      status: params.status,
      userId: params.user_id,
      appointment: {
        ...modal.form.appointment,
        appointmentTypeId: this.GET_APPOINTMENT_TYPE_ID_DEFAULT,
        attractionSourceId: this.GET_DEFAULT_ATTRACTION_SOURCE_ID,
      },
    }

    // попытаться найти дополнительные данные
    let requestPromise = null

    const clientId = params.clientId || modal.form.clientId

    if (clientId) {
      requestPromise = fetchClientById(clientId)
      modal.form.clientId = clientId
      modal.clientEdited = false
    }

    if (params.clientPhone) {
      requestPromise = fetchClientByPhone(JSON.stringify({ phone: params.clientPhone }))
    }

    if (requestPromise) {
      requestPromise
        .then((response) => {
          modal.form.client = {
            ...clientAdapter.toClient(response),
          }

          return response
        })
        .then((response) => clientGetSummary({
          client: { id: response.id },
          date: params.date,
          user_id: params.user_id,
        }))
        .then((clientSummary) => {
          if (params.attractionSourceId) {
            clientSummary.attractionSourceId = params.attractionSourceId
          }
          this.setClientSummary(clientSummary)
        })
        .finally(() => {
          this.stopLoading(modalType)
        })
    } else {
      this.stopLoading(modalType)
    }

    this.setDurationFromDoctor(modal, params.user)

    // переопределить телефон клиента, если он был указан отдельно
    if (params.clientPhone) {
      modal.form.client.phone = params.clientPhone
    }

    // Если запись на примерку
    if (params.dentalOrderId) {
      modal.form.dentalOrderId = params.dentalOrderId
    }

    if (params.attractionSourceId) {
      modal.form.appointment.attractionSourceId = params.attractionSourceId
    }

    if (this.modal.formData.doctor.denySmsInformation) {
      this.modal.formData.remindBeforeDate = false
      this.modal.formData.notifyAfterSave = false
      this.modal.formData.sendSmsConfirmation = false
    }
  },

  submitCreateAppointmentForm () {
    if (this.GET_APP_CONF.deny_record_client_from_blacklist && this.appointmentClientBlacklisted) {
      this.showAlert(t('client_in_blacklist_record_deny'))

      return
    }

    const modalType = MODAL_TYPES.CREATE_APPOINTMENT
    const modal = this.modal[modalType]

    if (modal?.loading) { return }

    if (!validateSnils(modal, this.setValidationMessages)) { return }

    this.startLoading(modalType)

    let request = Promise.resolve()
    if (modal.form.clientId) {
      if (modal.clientEdited) {
        request = clientEndpoint.update(
          modal.form.clientId,
          modal.form.client
        )
          .then(() => {
            modal.clientEdited = false
          })
      }
    } else {
      request = clientEndpoint.create(modal.form.client)
        .then((response) => {
          modal.form.clientId = response.id
          modal.clientEdited = false
        })
    }

    request
      .then(() => appointmentsEndpoint.create({
        ...modal.form.appointment,
        appointmentSourceId: modal.form.appointmentSourceId,
        clientId: modal.form.clientId,
        clinicId: modal.form.clinicId,
        date: modal.form.date,
        status: modal.form.status,
        time: modal.form.time,
        userId: modal.form.userId,
        sendSmsConfirmation: this.modal.formData.sendSmsConfirmation,
        remindBeforeDate: this.modal.formData.remindBeforeDate,
        notifyAfterSave: this.modal.formData.notifyAfterSave,
        dentalOrderId: modal.form.dentalOrderId,
        callId: this.GET_INSERT_PARAMS_APPOINTMENT?.callId,
      }))
      .then(this.successCreateAppointment)
      .catch((error) => this.setModalErrors(modalType, error))
      .finally(() => this.stopLoading(modalType))
  },

  clearCreateAppointmentForm () {
    const modalType = MODAL_TYPES.CREATE_APPOINTMENT
    const modal = this.modal[modalType]

    modal.clientEdited = true
    modal.clientPhoneMaskSettings = { ...Services.phoneMask.defaultMaskSettings }
    this.modal.formData.similarClients = {
      clients: [],
      selected: null,
    }

    this.modal.formData.clientSummary = { ...getDefaultFormClientSummary() }

    const { appointment_duration_id: appointmentDurationId } =
      this.getUserById(this.modal.createAppointment.form.userId) || {}

    const duration = this.GET_APPOINTMENT_DURATIONS
      .find(({ id }) => id === appointmentDurationId)?.duration || MIN_DURATION

    modal.form = {
      ...modal.form,
      clientId: null,

      client: {
        ...getDefaultFormClient(),
        ...getDefaultFormClientTabs(),
      },

      appointment: {
        ...getDefaultFormAppointment({
          defaultAttractionSourceId: this.GET_DEFAULT_ATTRACTION_SOURCE_ID || undefined,
        }),

        duration,
      },
    }

    /**
     * Сброс валидаций в $nextTick, потому что при сбросе данных валидации
     * срабатывают в следующем тике и убирать их надо там.
     * Использование skipValidation здесь только приведёт к созданию ещё кучи
     * шаблонного кода. Его можно использовать в компонентах, который валидируют
     * сами себя, а вот в таких случаях просто сбрасывать валидации в $nextTick.
     */
    this.$nextTick(() => {
      modal.validationMessages = {
        ...getDefaultValidationsClient(),
        ...getDefaultValidationsClientTabs(),
        ...getDefaultValidationsAppointment(),
      }
      this.markModalCleared(modalType)
    })
  },

  closeCreateAppointmentForm () {
    const modalType = MODAL_TYPES.CREATE_APPOINTMENT
    const modal = this.modal[modalType]

    this.closeModal(modalType)

    modal.clientTouched = false
    modal.clientEdited = true
    modal.forcedClientEdit = false
    this.reservedAppointmentShow = false

    modal.form = {
      appointmentSourceId: APPOINTMENT_SOURCE_MEDODS,
      date: '',
      time: '',
      clinicId: null,
      status: APPOINTMENT_STATUS.NEED_APPROVAL,
      userId: null,
    }

    this.clearCreateAppointmentForm()
  },

  successCreateAppointment () {
    // closeCreateAppointmentForm сбросит режим расписания в MODE_DEFAULT
    const cachedGridMode = this.GET_MODE_GRID_SCHEDULE
    const waitingListItemId = this.getSourceWaitingListItem()
    const client = this.modal[MODAL_TYPES.CREATE_APPOINTMENT].form.client

    this.closeCreateAppointmentForm()

    if (
      (cachedGridMode === MODE_WAITING_LIST_INSERT ||
      cachedGridMode === MODE_WAITING_LIST_MOVE) &&
      waitingListItemId
    ) {
      this.askDeleteFromWaitingList(waitingListItemId, client)
    }

    this.setGridMode($.scheduleGrid.MODE_DEFAULT)
    this.$store.dispatch('appointment/resetForm')
    this.$store.dispatch('resetInsertParams')
    Utils.URL.silentClearSearchParams()

    // если окно было открыто из другого места
    Intent.removeIntent()

    Notificator.success(t('record_successfully_created'))
  },

  getSourceWaitingListItem () {
    const waitingListItemId = Utils.URL.getSearchParam('waiting_list_item_id')

    if (waitingListItemId) {
      return waitingListItemId
    }

    const appointment = this['appointment/GET_MOVED_APPOINTMENT']
    if (appointment) {
      return appointment.waitingItemId
    }
  },

  /**
   * @param {number} waitingListItemId
   * @param {object} client
   */
  askDeleteFromWaitingList (waitingListItemId, client) {
    const shortName = formatter.formatValue(client, ['user:name'])
    this.showConfirmation(
      t('delete_client_from_waiting_list_body', [shortName]),
      () => {
        deleteWaitingListItem(waitingListItemId)
          .catch(Utils.reportError('deleteWaitingListItem'))
          .finally(() => {
            this.$store.commit('appointment/SET_MOVED_APPOINTMENT', null)
          })
      },
      () => {},
      t('delete_client_from_waiting_list_head')
    )
  },

  setDurationFromDoctor (modal, doctor) {
    const defaultDoctorDuration = this.GET_APPOINTMENT_DURATIONS
      .find((duration) => duration.id === doctor.appointment_duration_id)

    if (defaultDoctorDuration) {
      modal.form.appointment.duration = defaultDoctorDuration.duration
    }
  },
  //endregion create appointment

  //region edit appointment
  moveAppointmentFromWaitingList (appointment) {
    const modalType = MODAL_TYPES.EDIT_APPOINTMENT
    /**
     * Здесь модалка листа ожидания уже закрыта и убрана из контекста,
     * Поэтому чтобы единообразно обрабатывать редактирование записи в лист ожидания
     * в `submitEditAppointmentForm` нужно добавить её в контекст перед открытием
     * модалки редактирования записи на приём.
     */
    this.modalContext.push(MODAL_TYPES.EDIT_WAITING_ITEM)

    this.openModal(modalType)
    this.startLoading(modalType)
    this.fillEditAppointmentForm(appointment)
    this.loadAppointmentClientSummary(appointment)
      .catch(Utils.reportError('doctor_schedules_methods:moveAppointmentFromWaitingList'))
      .finally(() => {
        this.stopLoading(modalType)
      })
  },

  loadEditAppointmentForm (appointment) {
    // сбрасываем настройки смс, так как у них есть дефолтные значения,
    // и получается некрасиво, когда в форме редактирования сначала показываются они,
    // а потом подгружаются настройки записи
    this.modal.formData.sendSmsConfirmation = false
    this.modal.formData.remindBeforeDate = false
    this.modal.formData.notifyAfterSave = false

    const modalType = MODAL_TYPES.EDIT_APPOINTMENT
    this.openModal(modalType)
    this.startLoading(modalType)
    // Ждем маунт компонента,
    // Так как на слабых машинах маунт происходит медленнее и нарушается порядок выполнения
    this.$nextTick(() => {
      const requests = [
        this.loadEditableAppointment(appointment),
        this.loadAppointmentClientSummary(appointment),
        this.loadAppointmentWorkTimes(appointment),
      ]

      Promise.all(requests)
        .then(() => this.loadSmsForAppointment(appointment))
        .catch(Utils.reportError('doctor_schedules_methods:loadEditAppointmentForm'))
        .finally(() => {
          this.stopLoading(modalType)
        })
    })
  },

  fillEditAppointmentForm (appointment) {
    const doctor = appointment.user_id && appointment.clinic_id === this.GET_SCHEDULE_CURRENT_CLINIC_ID
      ? this.getUserById(appointment.user_id, ['id', 'short_name', 'avatar_tag'])
      : appointment.user

    this.modal.formData.doctor = {
      id: doctor.id,
      shortName: doctor.short_name,
      avatarTag: doctor.avatar_tag,
    }

    const modal = this.modal[MODAL_TYPES.EDIT_APPOINTMENT]

    modal.form = {
      ...modal.form,
      appointmentSourceId: appointment.appointment_source_id,
      date: appointment.date,
      time: appointment.time,
      status: appointment.status,
      userId: appointment.user_id,
      clientId: appointment.client.id,
      clinicId: appointment.clinic_id,
      appointmentId: appointment.id,
    }

    modal.form.client = {
      ...modal.form.client,
      ...clientAdapter.toClient(appointment.client),
    }

    modal.form.appointment = {
      ...modal.form.appointment,
      ...appointmentAdapter.toClient(appointment),
    }

    this.modal.formData.sendSmsConfirmation = appointment.send_confirmation_message
    this.modal.formData.remindBeforeDate = appointment.remind_before_date
    this.modal.formData.notifyAfterSave = appointment.notify_after_save
  },

  loadSmsForAppointment (appointment) {
    return getAppointmentLastSms(appointment.id)
      .then((lastMessage) => {
        const formAppointment = this.modal[MODAL_TYPES.EDIT_APPOINTMENT].form.appointment

        formAppointment.lastSmsConfirmation = { sms: lastMessage.sms.confirmation, whatsapp: lastMessage.whatsapp.confirmation }
        formAppointment.lastSmsNotify = { sms: lastMessage.sms.notify, whatsapp: lastMessage.whatsapp.notify }
        formAppointment.lastSmsRemind = { sms: lastMessage.sms.remind, whatsapp: lastMessage.whatsapp.remind }
      })
      .catch(Utils.reportError('doctor_schedules_methods:loadSmsForAppointment'))
  },

  loadEditableAppointment (appointment) {
    return fetchAppointmentById(appointment.id)
      .then(this.fillEditAppointmentForm)
  },

  loadAppointmentClientSummary (appointment) {
    const summaryParams = {
      client: { id: appointment.client.id },
      date: appointment.date,
      user_id: appointment.user_id,
    }

    return clientGetSummary(summaryParams).then((clientSummary) => {
      this.setClientSummary(clientSummary)
    })
  },

  loadAppointmentWorkTimes (appointment) {
    return fetchWorkTimesByUser({
      user_id: appointment.user?.id || appointment.userId,
      date: appointment.date,
    })
      .then((userWorkTimes) => {
        const workTimes = userWorkTimes.map((workTime) => workTime.work_time.split('-'))

        this.modal.formData.maxDuration = getAvailableDuration(
          workTimes,
          appointment.time
        )
      })
  },

  submitEditAppointmentForm () {
    const modalType = MODAL_TYPES.EDIT_APPOINTMENT
    const modal = this.modal[modalType]

    if (modal?.loading) { return }

    if (!validateSnils(modal, this.setValidationMessages)) { return }

    this.startLoading(modalType)

    let request = Promise.resolve()

    if (modal.form.clientId) {
      if (modal.clientEdited) {
        request = clientEndpoint.update(
          modal.form.clientId,
          modal.form.client
        )
          .then(() => {
            modal.clientEdited = false
          })
      }
    } else {
      request = clientEndpoint.create(modal.form.client)
        .then((response) => {
          modal.form.clientId = response.id
          modal.clientEdited = false
        })
    }

    request
      .then(() => appointmentsEndpoint.update({
        ...modal.form.appointment,
        appointmentSourceId: modal.form.appointmentSourceId,
        clientId: modal.form.clientId,
        clinicId: modal.form.clinicId,
        date: modal.form.date,
        status: modal.form.status,
        time: modal.form.time,
        userId: modal.form.userId,
        appointmentId: modal.form.appointmentId,
        sendSmsConfirmation: this.modal.formData.sendSmsConfirmation,
        remindBeforeDate: this.modal.formData.remindBeforeDate,
        notifyAfterSave: this.modal.formData.notifyAfterSave,
      }))
      .then(this.successEditAppointment)
      .catch((error) => this.setModalErrors(modalType, error))
      .finally(() => this.stopLoading(modalType))
  },

  clearEditAppointmentForm () {
    const modalType = MODAL_TYPES.EDIT_APPOINTMENT
    const modal = this.modal[modalType]

    modal.clientEdited = false
    modal.clientPhoneMaskSettings = { ...Services.phoneMask.defaultMaskSettings }
    this.modal.formData.similarClients = {
      clients: [],
      selected: null,
    }

    this.modal.formData.clientSummary = { ...getDefaultFormClientSummary() }

    const { appointment_duration_id: appointmentDurationId } =
      this.getUserById(this.modal.editAppointment.form.userId) || {}

    const duration = this.GET_APPOINTMENT_DURATIONS
      .find(({ id }) => id === appointmentDurationId)?.duration || MIN_DURATION

    modal.form = {
      ...modal.form,
      clientId: null,

      client: {
        ...getDefaultFormClient(),
        ...getDefaultFormClientTabs(),
      },
      appointment: {
        ...getDefaultFormAppointment(),
        duration,
      },
    }
    this.$store.dispatch('appointment/resetForm')

    this.$nextTick(() => {
      modal.validationMessages = {
        ...getDefaultValidationsClient(),
        ...getDefaultValidationsClientTabs(),
        ...getDefaultValidationsAppointment(),
      }
      this.markModalCleared(modalType)
    })
  },

  closeEditAppointmentForm () {
    const modalType = MODAL_TYPES.EDIT_APPOINTMENT
    const modal = this.modal[modalType]
    this.closeModal(modalType)
    modal.clientTouched = false
    modal.clientEdited = false

    modal.form = {
      date: '',
      status: APPOINTMENT_STATUS.NEED_APPROVAL,
      time: '',
      userId: null,
    }

    this.clearEditAppointmentForm()
  },

  successEditAppointment (updatedAppointment) {
    // Если редактируем из last_appointments
    const parentModalType = this.getParentModalType(MODAL_TYPES.EDIT_APPOINTMENT)
    if (parentModalType) {
      switch (parentModalType) {
        case MODAL_TYPES.LAST_APPOINTMENT: {
          const appointments = this.modal[parentModalType].appointments
          const index = appointments.findIndex((appointment) => {
            return appointment.id === updatedAppointment.id
          })
          this.$set(appointments, index, updatedAppointment)
          break
        }
        case MODAL_TYPES.EDIT_WAITING_ITEM: {
          this.removeLastModal(parentModalType)
          this.askDeleteFromWaitingList(this.getSourceWaitingListItem(), updatedAppointment.client)
        }
      }
    }
    this.closeEditAppointmentForm()
    this.setGridMode($.scheduleGrid.MODE_DEFAULT)
    Notificator.success(t('record_successfully_updated'))
  },
  //endregion edit appointment

  //region legal form
  addLegalRepresentative (legalRep) {
    const parentModalType = this.getParentModalType(MODAL_TYPES.LEGAL_REPRESENTATIVE)
    if (!parentModalType) return

    const newLegalRep = {
      id: legalRep.id,
      label: legalRep.short_info,
      name: legalRep.name,
      surname: legalRep.surname,
      second_name: legalRep.second_name || legalRep.secondName,
      relation_type_id: null,
      relation_type_title: '',
    }
    if ([MODAL_TYPES.CREATE_WAITING_ITEM, MODAL_TYPES.EDIT_WAITING_ITEM].includes(parentModalType)) {
      this.$store.commit('waitingList/ADD_LEGAL_REP', newLegalRep)
    } else {
      this.modal[parentModalType].form.client.legalRepresentatives.push(newLegalRep)
    }
  },

  closeLegalForm () {
    const modalType = MODAL_TYPES.LEGAL_REPRESENTATIVE
    const modal = this.modal[modalType]
    this.closeModal(modalType)

    modal.loading = false
    modal.disabled = false
    modal.clientTouched = false

    this.clearLegalForm()
  },

  clearLegalForm () {
    const modal = this.modal[MODAL_TYPES.LEGAL_REPRESENTATIVE]

    modal.clientPhoneMaskSettings = { ...Services.phoneMask.defaultMaskSettings }
    modal.form = {
      client: {
        ...getDefaultFormClient(),
        ...getDefaultFormClientTabs(),
      },
    }

    this.$nextTick(() => {
      modal.validationMessages = {
        ...getDefaultValidationsClient(),
        ...getDefaultValidationsClientTabs(),
      }
    })
  },
  //endregion legal form

  //region referral form
  submitReferralForm () {
    const { form } = this.modal.referral

    createReferral({
      referral: referralAdapter.toServer(form),
    })
      .then((response) => {
        const parentModal = this.getParentModalType(MODAL_TYPES.CREATE_REFERRAL)
        if (parentModal) {
          this.modal[parentModal].form.appointment.referral = formatUser(response)
        }
        this.closeReferralForm()
        Notificator.success(t('record_successfully_created'))
      })
      .catch((error) => this.setModalErrors(MODAL_TYPES.CREATE_REFERRAL, error))
      .finally(() => {
        this.modal.referral.loading = false
        this.modal.referral.disabled = false
      })
  },

  clearReferralForm () {
    this.modal.referral.form = { ...getDefaultFormReferral() }
    this.$nextTick(() => {
      this.modal.referral.validationMessages = { ...getDefaultValidationsReferral() }
    })
  },

  closeReferralForm () {
    this.closeModal(MODAL_TYPES.CREATE_REFERRAL)
    this.modal.referral.loading = false
    this.modal.referral.disabled = false

    this.clearReferralForm()
  },
  //endregion referral form

  //region company form
  submitCompanyForm (form) {
    this.setCreatedCompanyData(form)
  },

  // TODO: передавать компанию в другие модалки через vuex
  setCreatedCompanyData (company) {
    const parentModal = this.getParentModalType(MODAL_TYPES.COMPANY)
    if (!parentModal) return

    switch (parentModal) {
      case MODAL_TYPES.CREATE_REFERRAL: {
        this.modal[parentModal].form.company = company
        break
      }
      case MODAL_TYPES.LEGAL_REPRESENTATIVE: {
        this.$store.commit('legalRepresentatives/SET_COMPANY', company)
        break
      }
      default: {
        this.modal[parentModal].form.client.company = company
      }
    }
  },

  clearCompanyForm () {
    this.modal.company.form = getDefaultCompanyData()

    this.$nextTick(() => {
      this.modal.company.validationMessages = {
        ...getDefaultValidationsCompany(),
      }
    })
  },

  closeCompanyForm () {
    this.closeModal(MODAL_TYPES.COMPANY)
  },
  //endregion company form

  //region create reminder
  openCreateReminderItemForm (appointment) {
    this.modal.createReminderItem.visibility = true
    this.startLoading('createReminderItem')

    if (appointment) {
      this.modal.createReminderItem.showWrite = false
      this.modal.createReminderItem.form.client = appointment.client
      this.modal.createReminderItem.form.doctor = appointment.doctor
    }

    this.stopLoading('createReminderItem')
  },

  submitCreateReminderItemForm (write = false) {
    this.startLoading('createReminderItem')
    const { form } = this.modal.createReminderItem

    createReminder({
      reminder: reminderAdapter.toServer({
        ...form,
        clinicId: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
      }),
    })
      .then((response) => {
        const newReminder = {
          ...response,
          doctor: cloneDeep(form.doctor),
          responsible: cloneDeep(form.responsible),
        }

        this.successCreateReminderItem(newReminder, write)
      })
      .catch((error) => this.setModalErrors(MODAL_TYPES.CREATE_REMINDER, error))
      .finally(() => {
        this.stopLoading('createReminderItem')
      })
  },

  closeCreateReminderItemForm () {
    this.stopLoading('createReminderItem')
    this.modal.createReminderItem.visibility = false
    this.modal.createReminderItem.showWrite = true
    this.modal.createReminderItem.form = { ...getDefaultFormReminderItem() }
    this.$nextTick(() => {
      this.modal.createReminderItem.validationMessages = { ...getDefaultValidationsReminderItem() }
    })
  },

  successCreateReminderItem (response, write) {
    this.closeCreateReminderItemForm()
    Notificator.success(t('record_successfully_created'))
    if (write) {
      this.successCreateReminderItemAndWrite(response)
    } else {
      this.successCreateReminderItemDefault()
    }
  },

  successCreateReminderItemDefault () {
    this.setGridMode($.scheduleGrid.MODE_DEFAULT)
  },

  successCreateReminderItemAndWrite (response) {
    this.setGridMode($.scheduleGrid.MODE_CLIENT_INSERT)
    this.$store.commit('SET_INSERT_PARAMS_APPOINTMENT', {
      type: INSERT_APPOINTMENT_TYPES.REMINDER,
      clientId: response.client_id,
    })
    Utils.URL.silentSetQueryParam('insert_client', response.client_id)
  },
  //endregion create reminder

  //region edit reminder
  openEditReminderItemForm (item) {
    this.modal.editReminderItem.visibility = true
    this.startLoading('editReminderItem')

    this.modal.editReminderItem.reminderId = item.id
    this.modal.editReminderItem.form = {
      ...this.modal.editReminderItem.form,
      title: item.title,
      date: item.date,
      type: item.reminder_type,
      status: item.status,
      client: item.client,
      responsible: item.responsibles,
      doctor: item.doctor,
      note: item.note,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      createdBy: item.creator_name,
      updatedBy: item.editor_name,
    }

    this.stopLoading('editReminderItem')
  },

  submitEditReminderItemForm (write = false) {
    this.startLoading('editReminderItem')

    const { form } = this.modal.editReminderItem
    updateReminder(
      this.modal.editReminderItem.reminderId,
      {
        reminder: reminderAdapter.toServer({
          ...form,
          clinicId: this.GET_SCHEDULE_CURRENT_CLINIC_ID,
        }),
      }
    )
      .then((response) => this.successEditReminderItem(response, write))
      .catch((error) => this.setModalErrors(MODAL_TYPES.EDIT_REMINDER, error))
      .finally(() => {
        this.stopLoading('editReminderItem')
      })
  },

  askDestroyReminder () {
    this.showConfirmation(
      t('ask_delete_reminder'),
      this.destroyReminder,
      this.hideConfirmation,
      t('confirm_delete'),
      CONFIRMATION_MODES.DESTROY
    )
  },

  destroyReminder () {
    const modalType = MODAL_TYPES.EDIT_REMINDER
    const modal = this.modal[modalType]

    this.startLoading(modalType)
    reminderEndpoint.destroy(modal.reminderId)
      .then(() => {
        this.closeModal(modalType)
        Notificator.success(t('notifications.types.reminder.deleted'))
      })
      .catch(Utils.reportError('doctor_schedules:destroyReminder'))
      .finally(() => {
        this.stopLoading(modalType)
      })
  },

  closeEditReminderItemForm () {
    this.stopLoading('editReminderItem')
    this.modal.editReminderItem.visibility = false
    this.modal.editReminderItem.showWrite = true
    this.modal.editReminderItem.form = { ...getDefaultFormReminderItem() }
    this.$nextTick(() => {
      this.modal.editReminderItem.validationMessages = { ...getDefaultValidationsReminderItem() }
    })
  },

  successEditReminderItem (response, write) {
    this.closeEditReminderItemForm()
    Notificator.success(t('record_successfully_updated'))
    if (write) {
      this.successEditReminderItemAndWrite(response)
    } else {
      this.successEditReminderItemDefault()
    }
  },

  successEditReminderItemDefault () {
    this.setGridMode($.scheduleGrid.MODE_DEFAULT)
  },

  successEditReminderItemAndWrite (response) {
    this.setGridMode($.scheduleGrid.MODE_CLIENT_INSERT)
    this.$store.commit('SET_INSERT_PARAMS_APPOINTMENT', {
      type: INSERT_APPOINTMENT_TYPES.REMINDER,
      clientId: response.client_id,
    })
  },
  //endregion edit reminder

  //region last appointment
  createLastAppointmentsOrder () {
    if (this.modal.lastAppointment.selectedAppointments.length) {
      Turbolinks.visit(Routes.new_order_path(this.lastAppointmentOrderParams))
    }
  },

  chooseLastAppointmentClient (clientId) {
    fetchAppointmentByClient(clientId)
      .then((response) => {
        this.modal.lastAppointment = {
          ...this.modal.lastAppointment,
          client: response.client,
          appointments: response.appointments,
        }
        this.openModal(MODAL_TYPES.LAST_APPOINTMENT)
      })
  },

  createAppointmentFromLastAppointments (client) {
    const params = {
      mode: MODE_CLIENT_INSERT,
      clientId: client.id,
    }
    this.$store.dispatch('createAppointmentFromOutside', params)
  },

  moveLastAppointment (appointment) {
    this.$store.commit('SET_ACTIVE_APPOINTMENT', appointment)
    this.setGridMode($.scheduleGrid.MODE_MOVE)
  },

  cancelLastAppointment (appointment) {
    this.showConfirmation(t('reception.do_you_really_want_to_cancel_appointment'), () => {
      updateAppointmentStatus({ id: appointment.id, status: APPOINTMENT_STATUS.CANCELED })
        .then(() => {
          appointment.status = APPOINTMENT_STATUS.CANCELED
        })
        .catch(Utils.reportError('updateAppointmentStatus'))
    }, () => {
      this.closeModal(MODAL_TYPES.CONFIRMATION)
    })
  },

  onLastAppointmentsClose () {
    this.closeModal(MODAL_TYPES.LAST_APPOINTMENT)
    this.modal.lastAppointment.selectedAppointments = []
  },
  //endregion last appointment

  //region common methods
  setModalErrors (modal, error) {
    if (error instanceof Error) {
      Utils.reportError(`Error in modal ${modal}: `)(error)
    } else {
      const errors = error.responseJSON
      const messages = this.modal[modal].validationMessages
      this.setValidationMessages(messages, errors)
    }
  },

  clearFilterEntries () {
    this.filter = false
    this.filteredUserIds = []
  },

  //region modals
  markModalCleared (modalType) {
    const modal = this.modal[modalType]
    modal.cleared = true
    const unsubscribe = this.$watch(`modal.${modalType}.form`, () => {
      modal.cleared = false
      unsubscribe()
    }, { deep: true })
  },

  openCopyAppointmentWithServicesModal (yesEvent, noEvent, rejectEvent) {
    this.openModal(MODAL_TYPES.COPY_APPOINTMENT_WITH_SERVICES)
    this.modal.copyAppointmentWithServices.yesEvent = yesEvent
    this.modal.copyAppointmentWithServices.noEvent = noEvent
    this.modal.copyAppointmentWithServices.rejectEvent = rejectEvent
  },

  closeCopyAppointmentWithServicesModal () {
    this.closeModal(MODAL_TYPES.COPY_APPOINTMENT_WITH_SERVICES)
  },

  openUserSelectModal (userIds, selectedUserId, applyEvent, rejectEvent) {
    this.openModal(MODAL_TYPES.USER_SELECT)
    this.modal.userSelect.userIds = userIds
    this.modal.userSelect.selectedUserId = selectedUserId
    this.modal.userSelect.applyEvent = applyEvent
    this.modal.userSelect.rejectEvent = rejectEvent
  },

  closeUserSelectModal () {
    this.closeModal(MODAL_TYPES.USER_SELECT)
  },

  openCreateWorkTimeModal (
    headerMessage,
    message,
    onlyCreate,
    applyEvent,
    rejectEvent
  ) {
    this.openModal(MODAL_TYPES.CREATE_WORK_TIME)
    this.modal[MODAL_TYPES.CREATE_WORK_TIME].cabinetId = ''
    this.modal[MODAL_TYPES.CREATE_WORK_TIME].headerMessage = headerMessage
    this.modal[MODAL_TYPES.CREATE_WORK_TIME].message = message
    this.modal[MODAL_TYPES.CREATE_WORK_TIME].onlyCreate = onlyCreate
    this.modal[MODAL_TYPES.CREATE_WORK_TIME].applyEvent = applyEvent
    this.modal[MODAL_TYPES.CREATE_WORK_TIME].rejectEvent = rejectEvent
  },

  closeCreateWorkTimeModal () {
    this.closeModal(MODAL_TYPES.CREATE_WORK_TIME)
  },
  //endregion modals

  /**
   * @param {string} modal
   * @param {Record<string, any>} client
   * @param {boolean} [disable]
   */
  setDataToClientForm (modal, client, disable) {
    this.modal[modal].form.clientId = client.id
    this.modal[modal].form.client = {
      ...this.modal[modal].form.client,
      ...clientAdapter.toClient(client),
    }
    this.modal[modal].disable = !!disable
  },

  setSimilarSelectedToClientForm () {
    this.setDataToClientForm(
      this.modal.formData.similarClients.currentModal,
      this.modal.formData.similarClients.selected
    )

    this.modal.formData.similarClients.clients = []
    this.modal.formData.similarClients.selected = null
  },

  setClientSummary (summary) {
    this.modal.formData.clientSummary = summary
    if (summary.attractionSourceId) {
      this.modal[MODAL_TYPES.CREATE_APPOINTMENT].form.appointment.attractionSourceId = summary.attractionSourceId
    }
  },

  showAppointmentInSchedule ({ userId, date, appointmentId, clinicId }) {
    if (this.IS_ACTIVE_SCHEDULE_CABINETS) {
      this.setActiveScheduleStore(DOCTOR_SCHEDULE)
    }

    if (this.GET_SCHEDULE_CURRENT_CLINIC_ID !== clinicId) {
      this.setScheduleCurrentClinicId(clinicId)
    }

    this.focusOnAppointment = appointmentId

    this.clearFilterEntries()
    this.showAppointment = { userId, specialty: 0 }
    this.setScheduleDate(moment(date).format(this.$store.getters.GET_LOCALIZATION_DATE_FORMAT))
    this.setScheduleDaysCount(1)
  },

  createAppointmentInSchedule ({ userId, date, time, clinicId }) {
    if (this.IS_ACTIVE_SCHEDULE_CABINETS) {
      this.setActiveScheduleStore(DOCTOR_SCHEDULE)
    }

    if (this.GET_SCHEDULE_CURRENT_CLINIC_ID !== clinicId) {
      this.setScheduleCurrentClinicId(clinicId)
    }

    this.clearFilterEntries()
    this.showAppointment = { userId, specialty: 0 }
    this.setScheduleDate(moment(date).format(this.$store.getters.GET_LOCALIZATION_DATE_FORMAT))
    this.setScheduleDaysCount(1)

    const user = this.users.find((user) => user.id === userId)

    fetchWorkTimesByUser({ user_id: user.id, date })
      .then((userWorkTimes) => {
        const workTimes = userWorkTimes.map((workTime) => workTime.work_time.split('-'))

        this.openCreateAppointmentForm({
          date,
          time,
          user_id: user.id,
          user,
          _worktime: workTimes,
          appointment_source_id: APPOINTMENT_SOURCE_MEDODS,
          clinic_id: clinicId,
          status: APPOINTMENT_STATUS.NEED_APPROVAL,
        })
      })
  },

  /**
   * Так как все модалки, кроме waiting list прибиты гвоздями к doctor schedules,
   * нужно передавать путь к объекту клиента для нужной модалки.
   * Соответственно, для вставки демо клиента в модалки waiting list
   * этот метод вызывать не требуется, так как он реализован внутри модалок.
   *
   * @param {ClientStub} client
   * @param {string} modalPath
   */
  showDemoClient (client, modalPath) {
    this.setDemoClient(client, modalPath + '.form.client')
  },

  fetchSchedulesData (clinicId) {
    this.$store.commit('SET_CONTEXT_READY', false)
    this.$store.commit('SET_LOADING_DOCTOR_SCHEDULE', true)

    Promise.all([
      fetchSpecialties(clinicId),
      fetchAppointmentUsers({ clinic_ids: [this.GET_SCHEDULE_CURRENT_CLINIC_ID] }),
      fetchCabinets({ clinic_id: this.GET_SCHEDULE_CURRENT_CLINIC_ID }),
    ])
      .then(([specialties, users, cabinets]) => {
        // Кэш наполняется для jquery-плагина расписания
        AppCache.reset('user', users)
        AppCache.reset('cabinet', cabinets.concat({
          id: WITHOUT_CABINET_ID,
          title: t('doctors_without_a_cabinet'),
        }))
        this.$store.commit('SET_SCHEDULE_USERS_LIST', users)
        this.users = users
        this.cabinets = [{ id: WITHOUT_CABINET_ID, title: t('doctors_without_a_cabinet') }, ...cabinets]
        this.specialties = specialties
      })
      .catch(Utils.reportError('fetchSchedulesData'))
      .finally(() => {
        this.$nextTick(() => {
          this.$store.commit('SET_CONTEXT_READY', true)
          this.$store.commit('SET_LOADING_DOCTOR_SCHEDULE', false)
        })
      })
  },

  fetchCompanyTypes () {
    fetchCompanyTypes()
      .then((companyTypes) => {
        this.modal.company.companyTypes =
          companyTypes.data.map((type) => ({ id: type.id, title: type.title }))
        this.modal.company.loadingTypes = false
      })
  },

  fetchClientSummaryInfo () {
    const { similarClients } = this.modal.formData
    const currentModal = similarClients.currentModal

    const summaryParams = {
      client: { id: this.modal[currentModal].form.clientId },
      date: this.modal[currentModal].form.date,
      user_id: this.modal.formData.doctor.id,
    }
    clientGetSummary(summaryParams).then(this.setClientSummary)
  },

  startLoading (modalName) {
    this.modal[modalName].loading = true
  },

  stopLoading (modalName) {
    this.modal[modalName].loading = false
  },

  resetModalFormData () {
    this.modal.formData = getFormData(this.$store)
  },

  resetSnils (prop) {
    this.modal[prop].validationMessages.snils = []
    this.modal[prop].validationMessages.other.snils = []
    this.modal[prop].form.client.snils = ''
  },

  updateAppointmentDuration (duration) {
    this.modal.createAppointment.form.appointment.duration = duration
    this.reservedAppointment.duration = duration
  },

  modalHasErrors (modal) {
    return Object
      .values(this.modal[modal].validationMessages)
      .flatMap((messages) => {
        return Array.isArray(messages)
          ? messages
          : Object.values(messages)
      })
      .some((el) => el.length > 0)
  },
  //endregion common methods

  // startRegion promobot
  subscribeToPromobotPubSubEvents () {
    window.PubSub.on('promobotDataScanned', (target, data) => { this.onPromobotDataScanned(target, data) })
    window.PubSub.on('promobotDataScanStarted', (target, data) => { this.onPromobotDataScanStarted(target, data) })
    window.PubSub.on('promobotDataScanFinished', (target, data) => { this.onPromobotDataScanFinished(target, data) })
  },

  /**
   * @param {string} target
   * @param {{ type: DocTypes, data: DocumentData[] }} scannedData
   */
  onPromobotDataScanned (target, scannedData) {
    const filteredModalContext = (this.modalContext || [])
      .filter((modalContext) => PROMOBOT_PERMIT_MODAL_TYPES.includes(modalContext))

    if (!filteredModalContext.length) { return }

    const modal = this.modal[filteredModalContext.at(-1)]
    const client = modal.form.client

    const { type, data, doc_type: docType, results } = scannedData

    fillClientFormByPromobotData(
      client,
      results || data,
      {
        type: type || docType,
        documentTypes: this.GET_DOCUMENT_TYPES,
      }
    )
  },

  onPromobotDataScanStarted (target, data) {
    this.outerDataLoading = true
  },

  onPromobotDataScanFinished (target, data) {
    this.outerDataLoading = false
  },
  // endRegion promobot
}
