<template>
  <span class="appointment-status">
    <span :class="iconClass" />
    {{ statusText }}
  </span>
</template>

<script>
import { i18nScopeMixin } from '@/vue_present/mixins/i18nScopeMixin'

export default {
  name: 'AppointmentStatus',

  mixins: [i18nScopeMixin],

  props: {
    status: {
      type: Number,
      required: true,
    },
  },

  data () {
    return {
      // statuses, from 1 to 9
      statuses: [
        {}, //костыль т.к. нет статуса с enum соответствующим 0
        { // approved
          icon: 'appointment-status-icon fad fa-fw fa-circle',
          title: this.t$('approved'),
        }, { // need_approval
          icon: 'appointment-status-icon fad fa-fw fa-fw fa-circle-notch',
          title: this.t$('need_approval'),
        }, { // billed
          icon: 'appointment-status-icon fad fa-fw fa-credit-card',
          title: this.t$('billed'),
        }, { // canceled
          icon: 'appointment-status-icon fad fa-fw fa-times',
          title: this.t$('canceled'),
        }, { // failed
          icon: 'appointment-status-icon fad fa-fw fa-user-times',
          title: this.t$('failed'),
        }, { // arrived
          icon: 'appointment-status-icon fad fa-fw fa-user',
          title: this.t$('arrived'),
        }, { // serviced
          icon: 'appointment-status-icon fad fa-fw fa-clipboard-check',
          title: this.t$('serviced'),
        }, { // paid
          icon: 'appointment-status-icon fad fa-fw fa-check-square',
          title: this.t$('paid'),
        }, { // confirmed_by_administrator
          icon: 'appointment-status-icon fad fa-fw fa-thumbs-up',
          title: this.t$('confirmed_by_administrator'),
        },
      ],
    }
  },

  computed: {
    iconState () {
      return this.statuses[this.status] || {}
    },

    statusText () {
      return this.iconState.title || ''
    },

    iconClass () {
      return this.iconState.icon || ''
    },
  },

  created () {
    this.setI18nScope('enums.appointment.status')
  },
}
</script>
