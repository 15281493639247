import IntroductionModal from '@/vue_components/modal_windows/introduction/introduction_modal.vue'
import { createVueApp } from '@/vue_components/create_vue_app'

// В методе создаётся модальное окно для заглушек
// elementId - элемент, на котором будет смонтирован компонент
// icon - иконка перед текстом модального окна
// innerText - текст модального окна
// titleText - текст заголовка модального окна, если '', то "Модуль не подключен" (+locale)
// button - text + url, текст на кнопке модального окна, если '', то "ПОДКЛЮЧИТЬ МОДУЛЬ" (+locale)
export function introductionModalBuilder (elementId, icon, innerText, titleText = '', button = { text: '', url: '' }) {
  if (!elementId || !icon || !innerText) return null

  // создание блока для монтирования
  $('body').append(`<div id="${elementId}">`)

  // компонент
  return createVueApp({
    el: `#${elementId}`,
    render: (h) => h(IntroductionModal, {
      props: {
        modalIcon: icon,
        modalInner: innerText,
        modalTitle: titleText,
        modalButton: button,
      },
    }),
    useChildren: true,
  })
}
